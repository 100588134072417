<div class="absolute flex w-full justify-center">
    <div
        class="relative flex w-fit items-center border border-twClassicOrange bg-twLightOrange px-2 py-1.5 text-[13px]">
        <app-warning-hex class="mr-[15px] size-5 text-twClassicOrange" />
        <p class="mr-2 font-light">{{ text() }}</p>

        <button
            class="h-[26px] w-[129px] rounded-card bg-twClassicOrange px-3 py-1 font-semibold text-twTextInverse"
            (click)="handleSubscribe()">
            Subscribe Now
        </button>
    </div>
</div>
