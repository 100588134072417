import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, OnInit, computed, inject, input, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AvatarMenuComponent } from '@core/layout/avatar-menu/avatar-menu.component';
import { AccountService } from '@core/services/admin/account.service';
import { UserService } from '@core/services/admin/user.service';
import { MAIN_SLUGS } from '@routes/routes.types';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { AnalyticsIconComponent } from '@shared/components/icons/analytics-icon/analytics-icon.component';
import { AutomateIconComponent } from '@shared/components/icons/automate-icon.component';
import { BagIconComponent } from '@shared/components/icons/bag-icon/bag-icon.component';
import { CreditCardIconComponent } from '@shared/components/icons/credit-card-icon/credit-card-icon.component';
import { DashboardIconComponent } from '@shared/components/icons/dashboard-icon/dashboard-icon.component';
import { InboxIconComponent } from '@shared/components/icons/inbox-icon/inbox-icon.component';
import { LoudSpeakerIconComponent } from '@shared/components/icons/loud-speaker-icon/loud-speaker-icon.component';
import { PeopleIconComponent } from '@shared/components/icons/people-icon/people-icon.component';
import { handleImgLoadError } from '@shared/utils/image';
import { filter, map } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        NgOptimizedImage,
        AvatarMenuComponent,
        AvatarComponent,
        DashboardIconComponent,
        InboxIconComponent,
        BagIconComponent,
        PeopleIconComponent,
        LoudSpeakerIconComponent,
        AutomateIconComponent,
        CreditCardIconComponent,
        AnalyticsIconComponent,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly userS = inject(UserService);
    readonly accountS = inject(AccountService);

    readonly MAIN = MAIN_SLUGS;

    profileImg = computed(() => this.userS.user()?.avatar);
    name = computed(() => this.userS.user()?.name);
    isMenuOpen = false;
    isSideBarOpen = input(false);
    lastSlug = signal('');
    analyticsv2 = ['listening', 'users'];
    isAnalytics = computed(() => this.analyticsv2.includes(this.lastSlug()));

    @HostListener('document:click', ['$event']) onDocumentClick() {
        if (this.isMenuOpen) {
            this.isMenuOpen = false;
        }
    }

    ngOnInit() {
        this.router.events
            .pipe(
                filter((event: any) => event instanceof NavigationEnd || 'routerEvent' in event),
                map((event: any) => event?.url || event?.routerEvent!.url)
            )
            .subscribe(url => {
                const lastUrlSegment = url.split('?')[0].split('/').pop();
                this.lastSlug.set(lastUrlSegment);
            });
    }

    handleAvatarClick(event: Event) {
        event.stopPropagation();
        this.isMenuOpen = !this.isMenuOpen;
    }

    handleImgErr(event: Event) {
        handleImgLoadError(event, 'assets/default-avatar.png');
    }

    getActiveRoute() {
        const current = this.router.url.split('main/');
        return current[1];
    }
}
