import { Route, Routes } from '@angular/router';
import { BILLING_SLUGS, SETTINGS_SLUGS } from '@routes/routes.types';
import { subscriptionCompletedGuard } from './guards/subscription-completed.guard';

export const BILLING_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: BILLING_SLUGS.dashboard,
    },
    {
        path: BILLING_SLUGS.dashboard,
        title: 'Dashboard',
        loadComponent: () =>
            import(
                '@features/settings/billing/components/billing-dashboard/billing-dashboard.component'
            ).then(m => m.BillingDashboardComponent),
    },
    {
        path: BILLING_SLUGS.subscribe,
        title: 'Subscribe',
        loadComponent: () =>
            import(
                '@features/settings/billing/components/initial-pricing/initial-pricing.component'
            ).then(m => m.InitialPricingComponent),
    },
    {
        path: '**',
        redirectTo: BILLING_SLUGS.dashboard,
    },
];

export const BILLING_ROUTE: Route = {
    path: SETTINGS_SLUGS.billing,
    title: 'Billing',
    loadComponent: () =>
        import('@features/settings/billing/billing.component').then(m => m.BillingComponent),
    children: BILLING_ROUTES,
    canDeactivate: [subscriptionCompletedGuard],
};

export const WITHOUT_GROWTH_PLAN_ROUTE = {
    path: BILLING_SLUGS.withoutGrowthPlan,
    loadComponent: () =>
        import(
            '@features/settings/billing/components/needs-upgrade-landing/needs-upgrade-landing.component'
        ).then(m => m.NeedsUpgradeLandingComponent),
};
