import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SETTINGS_SLUGS } from '@routes/routes.types';
import { BillingService } from '../services/billing.service';

export const subscriptionGuard: CanActivateFn = () => {
    const billingS = inject(BillingService);
    const router = inject(Router);
    const isInBilling = router.url.includes(SETTINGS_SLUGS.billing);
    let isValid = true;
    effect(() => {
        const isExpired = billingS.periodEndExpired();
        isValid = !isExpired || isInBilling;
        if (!isValid) {
            billingS.goToBilling();
        }
    });

    return isValid;
};
