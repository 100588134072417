import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {
    APP_SLUGS,
    BILLING_SLUGS,
    MAIN_SLUGS,
    PAYMENT_SLUG_SYNC,
    SETTINGS_SLUGS,
} from '@routes/routes.types';
import { ToastrService } from 'ngx-toastr';
import { BILLING_PLAN_TYPE, ROUTES_BLOCKED_FOR_STARTER } from '../billing.types';
import { BillingService } from '../services/billing.service';

export const subscriptionLevelGuard: CanActivateFn = (route, state) => {
    const billingS = inject(BillingService);
    const router = inject(Router);
    const toastrS = inject(ToastrService);
    let isBlocked = false;
    effect(() => {
        const planVariant = billingS.planVariant();
        if (!planVariant) return false;
        const isStarter = planVariant === BILLING_PLAN_TYPE.starter;
        const exceptionRoute = BILLING_SLUGS.withoutGrowthPlan;
        const prevPath = state.url;
        const isInExceptionRoute = prevPath.includes(exceptionRoute);
        if (!isStarter) {
            if (isInExceptionRoute) {
                goToRootRoute(prevPath, router);
            }
            return true;
        }

        const path = route?.routeConfig?.path;
        if (!path) return false;
        isBlocked = ROUTES_BLOCKED_FOR_STARTER.includes(path) && !isInExceptionRoute;
        if (isBlocked) {
            goToWithoutGrowthPlan(path, router, toastrS);
        }
    });
    return !isBlocked;
};

const goToWithoutGrowthPlan = (path: string, router: Router, toastrS: ToastrService) => {
    let url = '';

    switch (path) {
        case MAIN_SLUGS.CAMPAIGNS:
            url = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.CAMPAIGNS}/${BILLING_SLUGS.withoutGrowthPlan}`;
            router.navigateByUrl(url);
            break;

        case MAIN_SLUGS.AUTOMATION:
            url = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.AUTOMATION}/${BILLING_SLUGS.withoutGrowthPlan}`;
            router.navigateByUrl(url);

            break;
        case PAYMENT_SLUG_SYNC.sync:
            url = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.PAYMENT}/${BILLING_SLUGS.withoutGrowthPlan}`;
            router.navigateByUrl(url);
            break;
        case SETTINGS_SLUGS.payment:
            toastrS.warning('You need to upgrade your plan to access this feature');
            break;
    }
};
const goToRootRoute = (prevPath: string, router: Router) => {
    const url = prevPath.replace(`/${BILLING_SLUGS.withoutGrowthPlan}`, '');
    router.navigateByUrl(url);
};
