import { Routes } from '@angular/router';
import { BILLING_ROUTE } from '@features/settings/billing/billing.routes';
import { subscriptionLevelGuard } from '@features/settings/billing/guards/subscription-level.guard';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { DEPARTMENTS_SLUGS, SETTINGS_SLUGS } from './routes.types';

export const DEPARTMENTS_ROUTES: Routes = [
    {
        path: DEPARTMENTS_SLUGS.table,
        title: 'Table',
        loadComponent: () =>
            import(
                '@features/settings/departments/departments-table/departments-table.component'
            ).then(m => m.DepartmentsTableComponent),
    },
    {
        path: DEPARTMENTS_SLUGS.form,
        title: 'Form',
        loadComponent: () =>
            import(
                '@features/settings/departments/departments-form/departments-form.component'
            ).then(m => m.DepartmentsFormComponent),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: DEPARTMENTS_SLUGS.table,
    },
];

export const SETTINGS_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'settings/profile',
    },
    {
        path: SETTINGS_SLUGS.settings,
        pathMatch: 'full',
        redirectTo: 'settings/profile',
    },
    {
        path: SETTINGS_SLUGS.settings,
        title: 'Settings',
        loadComponent: () =>
            import('@features/settings/settings.component').then(m => m.SettingsComponent),
        children: [
            {
                path: SETTINGS_SLUGS.profile,
                title: 'Profile',
                loadComponent: () =>
                    import('@features/settings/profile/profile.component').then(
                        m => m.ProfileComponent
                    ),
                canActivate: [subscriptionGuard],
            },
            BILLING_ROUTE,
            {
                path: SETTINGS_SLUGS.payment,
                title: 'Payment',
                loadComponent: () =>
                    import('@features/settings/payment-settings/payment-settings.component').then(
                        m => m.PaymentSettingsComponent
                    ),
                canActivate: [subscriptionGuard, subscriptionLevelGuard],
            },
            {
                path: SETTINGS_SLUGS.taxes,
                title: 'Taxes',
                loadComponent: () =>
                    import('@features/settings/taxes/taxes.component').then(m => m.TaxesComponent),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.users,
                title: 'Users',
                loadComponent: () =>
                    import('@features/settings/set-users/set-users.component').then(
                        m => m.SetUsersComponent
                    ),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.departments,
                title: 'Departments',
                loadComponent: () =>
                    import('@features/settings/departments/departments.component').then(
                        m => m.DepartmentsComponent
                    ),
                canActivate: [subscriptionGuard],
            },
        ],
    },
    {
        path: SETTINGS_SLUGS.checkout,
        title: 'Checkout',
        loadComponent: () =>
            import('@features/settings/checkout-page/checkout.component').then(
                m => m.CheckoutComponent
            ),
    },
    {
        path: SETTINGS_SLUGS.cancel,
        title: 'Cancel plan',
        loadComponent: () =>
            import('@features/settings/cancel-subscript-page/cancel-subscript-page.component').then(
                m => m.CancelSubscriptPageComponent
            ),
    },
];
